import { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Spinner from '../../component/spinner/Spinner';
import { postSendEmail } from '../../service/ApiLeiten';

export default function VisitWorkView(props) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData(form.current);
    const data = Object.fromEntries(formData.entries());

    // definimos el origen de solicitud del pedido de mail
    const tipo = props.tipo || 'visita';
    data.tipo = tipo;
    console.log(tipo);
    

    try {
      const response = await postSendEmail(data);

      if (response === 'error') {
        window.Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un problema al enviar su mensaje.',
        });
      } else {
        window.Swal.fire({
          icon: 'success',
          title: 'Mensaje enviado',
          text: 'Su mensaje ha sido enviado correctamente.',
        });
        form.current.reset(); // Limpiar el formulario
      }
    } catch (error) {
      window.Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Hubo un problema al enviar su mensaje: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyleVisitWorkView>
      {loading && (
        <SpinnerOverlay>
          <Spinner />
        </SpinnerOverlay>
      )}
      <div className={props.img === 'none' ? 'containerPortal marginTopRepair' : 'containerPortal marginTopVisit'}>
        <div className="row">
          <div className={props.img === 'none' ? 'col-12' : 'col-lg-5'}>
            <div className="containerForm">
              <h3 className="title">{props.title ? props.title : 'Solicitar una Visita a Obra' }</h3>
              <form ref={form} onSubmit={sendEmail}>
                <label className="form-label">Nombre y Apellido: </label>
                <input className="form-control" type="text" name="name" required />
                <label className="form-label">Empresa: </label>
                <input className="form-control" type="text" name="company" required />
                <label className="form-label">Email: </label>
                <input className="form-control" type="email" name="email" required />
                <label className="form-label">Télefono: </label>
                <input className="form-control" type="number" name="phone" required />
                <label className="form-label">Dirección de Obra: </label>
                <input className="form-control" type="text" name="address" required />
                <label className="form-label">Contacto en Obra: </label>
                <input className="form-control" type="text" name="contact" required />
                <label className="form-label">Marca y Modelo del Equipo: </label>
                <input className="form-control" type="text" name="product" required />
                <label className="form-label">Falla: </label>
                <input className="form-control" type="text" name="failure" required />

                <input className="btn btn-warning" style={{ marginTop: '1rem' }} type="submit" value="Solicitar Servicio" />
              </form>
            </div>
          </div>
          {props.img !== 'none' && (
            <div className="col-lg-7" id='img'>
              <div className="containerImg">
                <img src="../../../assets/img/postVenta.jpg" alt="Reparación en Obra" />
              </div>
            </div>
          )}
        </div>
      </div>
    </StyleVisitWorkView>
  );
}

const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const StyleVisitWorkView = styled.div`
  .marginTopVisit {
    margin-top: 2rem;
  }
  .marginTopRepair {
    margin-top: 0rem;
  }
  .title {
    text-align: center;
  }
  .containerForm {
    padding: 2rem 0rem;
  }

  .containerImg {
    padding: 2rem 0rem;
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  label {
    margin-top: 0.5rem;
  }
`;
